import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Food Logging`}</h1>
    <p>{`The Food Logging SDK is a fully featured meal tracking solution that allows
you to create users and log meals on their behalf using images, text search
and barcodes. It aims to automate food input by combining user's eating
history and context with the food recognition and knowledge graph APIs to
offer a higher accuracy system that's personalized to each user. `}</p>
    <h2>{`Concepts`}</h2>
    <h3>{`Meals`}</h3>
    <p>{`A Meal consists of one or more Entries and one or more Images. For instance a meal could be
composed of a salad, beverage, and dessert. Each of these will be represented by one
or more entries with an image per course. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"422d6199-c1b6-4037-866f-e28c1ea0b0ca"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"title"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Oatmeal for Breakfast"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"description"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"I had oatmeal for breakfast again today. It's full of fiber."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"images"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"entries"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_done"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"local_eaten_at_time"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2019-02-27T08:59:09.937098"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"utc_eaten_at_time"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2019-02-27T10:59:09.937098"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_created"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2019-02-27T11:27:05.971980Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_modified"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2019-02-27T11:27:05.972097Z"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Entries`}</h3>
    <p>{`A Meal Entry consists of a `}<a parentName="p" {...{
        "href": "/docs/food_knowledge_base/#item"
      }}>{`Food Item`}</a>{` and optionally additional information to indicate which nutrition fact was selected, what the serving size was and which image contains the item. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` entry identifier (autogenerated uuid)
  item`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` item consumed (uuid)
  nutrition_fact`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` id of nutrition fact selected for the given item (optional uuid)
  serving_amount`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` serving amount consumed for the selected nutrition_fact (optional float)
  image`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` id of image that contains this entry (optional uuid)
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Only `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`item`}</code>{` is required. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nutrition_fact`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`serving_amount`}</code>{` can be omitted if nutrition information is not required for your application.`}</p>
    <p>{`If a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nutrition_fact`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`serving_amount`}</code>{` are specified the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`item`}</code>{` id can be omitted as it will be inferred from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nutrition_fact`}</code>{`.`}</p>
    <p>{`An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`image`}</code>{` id should be specified to specify which image the entry appears in. `}</p>
    <p>{`A complete entry will have the following form:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"da17c010-e2b0-498d-b322-81ab3f354295"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"item"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1e0d5c1a-08fd-4228-89b5-ce9675eb0133"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Brown Rice"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"no fat added in cooking"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nutrition_facts"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cf809f2a-11df-4a9d-8ad8-2c705fd50e5c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"item"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1e0d5c1a-08fd-4228-89b5-ce9675eb0133"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"serving"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"amount"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"unit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1f49b3a4-b335-492d-88b6-9f63f7ae0837"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cup"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_singular_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cups"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"abbreviated_plural_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"measure_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"volume"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"granularity"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"details"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"US Customary Unit"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"grams"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`195`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"servings_per_package"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nutrition"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"calories"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`214.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          ...
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"is_default"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      ...
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nutrition_fact"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cf809f2a-11df-4a9d-8ad8-2c705fd50e5c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"serving_amount"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1.5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"image"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2f49b3a4-b335-492d-88b6-9f63f7ae0837"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_created"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2018-03-27T07:00:59.234598Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_modified"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2018-03-27T07:00:59.234739Z"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Images`}</h3>
    <p>{`Images can be uploaded by file, base64 encoding, multipart form, or by url reference. See
`}<a parentName="p" {...{
        "href": "/docs/image_input_in_the_api/"
      }}>{`inputting images into the API`}</a>{` for more information. Once uploaded they can
analyzed using the vision endpoints or added to a meal for suggestions.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      